import './bootstrap';
import '../css/app.scss';
import {createApp, DefineComponent, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/index';
import PrimeVue from 'primevue/config';
import Lara from '@presets/lara';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import {usePassThrough} from "primevue/passthrough";
import {it} from "primelocale/it.json";
import LaravelPermissionToVueJS from 'laravel-permission-to-vuejs'

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

const CustomPreset = usePassThrough(
    Lara,
    {
        button: {
            root: ({ props }) => ({
                class: {
                    'bg-sikuro-blue-500': props.severity==='sikuro-blue',
                    'text-white': props.severity==='sikuro-blue',
                    'hover:bg-sikuro-blue-600': props.severity==='sikuro-blue',

                    'bg-sikuro-yellow-500': props.severity==='sikuro-yellow',
                    'hover:bg-sikuro-yellow-600': props.severity==='sikuro-yellow',
                }
            })
        }
    },
    {
        mergeSections: true,
        mergeProps: true
    }
);

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(PrimeVue, {
                unstyled: true,
                pt: CustomPreset,
                locale: it
            })
            .use(ConfirmationService)
            .use(ToastService)
            .use(VueTippy, {
                directive: 'tippy'
            })
            .use(LaravelPermissionToVueJS)
            .mount(el);
    },
    progress: {
        color: '#d6b602',
    },
});
